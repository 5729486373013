<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation autocomplete="new-password">
			<ti-card :icon="$icons.certificate">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Certificate</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this course?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template #sidebar>
						<v-col cols="12">
							<v-select v-model="certificate.status" :items="['Draft', 'Published', 'Archived']" label="Status"></v-select>
						</v-col>
						<v-col cols="12">
							<v-select v-model="certificate.brand_id" :items="brands" item-text="name" item-value="id" label="Brand"></v-select>
						</v-col>
						<v-col cols="12">
							<v-text-field type="number" v-model="certificate.completion_percent" min="0" max="100" label="Completion percentage requirement"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-checkbox v-model="certificate.is_cim" label="CIM Certificate"></v-checkbox>
						</v-col>
						<v-col cols="12">
							<ti-image-upload v-model="certificate.featured_image_id" label="Featured image" :image-id="certificate.featured_image_id" :key="certificate.id"></ti-image-upload>
						</v-col>
						<v-col cols="12">
							<ti-image-upload v-model="certificate.share_image_id" label="Share image" :image-id="certificate.share_image_id" :key="certificate.id"></ti-image-upload>
						</v-col>
						<v-col cols="12">
							<v-checkbox v-model="certificate.show_benchmark_quiz" label="Show benchmark quiz" hint="Shows the benchmark quiz as part of the certificate" persistent-hint></v-checkbox>
						</v-col>
						<v-col cols="12">
							<v-select v-model="certificate.corporate_owner" label="Corporate owner" :items="corporates" item-text="name" item-value="id"></v-select>
						</v-col>
					</template>
					<template>
						<v-col cols="12">
							<v-text-field v-model="certificate.name" :rules="validations.name" label="Name"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="certificate.description" label="Description"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="certificate.internal_name" label="Internal name"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<ti-card class="mt-5">
				<template #title>Associated pathways</template>
				<v-card-text>
					<v-list>
						<v-list-item v-for="path in certificate.pathways" :href="`https://admin2.targetinternet.com/certificate_pathways/${path.id}/details`" target="_blank">
							<v-list-item-title>{{ path.name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-card-text>
			</ti-card>
			<ti-card class="mt-5">
				<template #title>Resources</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="openAddDialog">
						<v-icon>{{ $icons.plus }}</v-icon>
					</v-btn>
				</template>
				<v-card-text>
					<v-row v-for="(certification_resource, index) in certificate_resources_ordered" :key="certification_resource.id">
						<v-col cols="1" class="d-flex align-center">
							<span>{{ certification_resource.order }}</span>
						</v-col>
						<v-col cols="2" class="grow d-flex align-center">
							<v-combobox
								v-model="certification_resource.section"
								label="Section"
								:items="sections"
							></v-combobox>
<!--							<p class="ma-0 text&#45;&#45;black"> {{ certification_resource.section }}</p>-->
						</v-col>
						<v-col cols="2" class="grow d-flex align-center">
							<p class="ma-0 text--black"> {{ getBrandName(brands, certification_resource.brand_id) }}</p>
						</v-col>
						<v-col cols="5" class="grow d-flex align-center">
							<v-autocomplete
								v-model="certification_resource.resource_id"
								:items="resources_for_select"
								:item-text="(item) => item.type === 'ExternalResource' ? `${item.title} - External Resource` : `${item.title} - ${item.brand} - ${expertiseLabel(item.expertise)}`"
								:item-value="(item) => item.id"
								label="Select resource"
							></v-autocomplete>
						</v-col>
						<v-col cols="2" class="d-flex align-center">
							<v-btn icon @click="certificationResourceOrderUp(certification_resource, index)">
								<v-icon>{{ $icons.up }}</v-icon>
							</v-btn>
							<v-btn icon @click="certificationResourceOrderDown(certification_resource, index)">
								<v-icon>{{ $icons.down }}</v-icon>
							</v-btn>
							<v-btn icon color="error" @click="destroyResource(certification_resource, index)">
								<v-icon>{{ $icons.delete }}</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</ti-card>
		</v-form>


		<v-dialog v-model="addDialog" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Add resource</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-autocomplete
									v-model="newResource.resource_id"
									:items="resources_for_select"
									:item-text="(item) => item.type === 'ExternalResource' ? `${item.title} - External Resource` : `${item.title} - ${item.brand} - ${expertiseLabel(item.expertise)}`"
									:item-value="(item) => item.id"
									label="Select resource"
								></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-combobox
									v-model="newResource.section"
									label="Section"
									:items="sections"
								></v-combobox>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" text @click="addDialog = false">Close</v-btn>
					<v-btn color="success" @click="addResource">Add</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import {call, sync} from "vuex-pathify";
import helperMixin from "@/mixins/helperMixin";
import TiForm from "./Form"
import {arrayMoveMutable} from "array-move";
import models from '@/models'
import _find from "lodash/find";
import _uniqBy from 'lodash/uniqBy'

export default {
	name: "CertificatesForm",
	props: ['id'],
	mixins: [helperMixin],
	components: {TiForm},
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		certificate: sync('certificates/certificate'),
		brands: sync('brands/brands'),
		resources: sync('resources/resources'),
		corporates: sync('corporates/corporates'),
		resources_for_select(){
			return this.resources.filter((r) => (r.brand_id === null || r.brand_id === this.certificate.brand_id)).sort((a, b) => a?.title - b?.title);
		},
		certificate_resources_ordered() {
			if (this.certificate?.certification_resources_attributes.length < 1) return [];
			return this.certificate.certification_resources_attributes.filter((a) => !a._destroy).sort((a, b) => a?.order - b?.order);
		},
		sections(){
			return _uniqBy(this.certificate?.certification_resources_attributes.map(i => i.section), item => item)
		}
	},
	data: () => ({
		valid: false,
		addDialog: false,
		validations: {
			name: validationRules('Name', ['required', 'minLength:2']),
		},
		newResource: models.Certification().certification_resources_attributes[0]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('brands/getBrands!', null)
			this.$store.set('resources/getResources!', null)
			this.$store.set('corporates/getCorporates!', null)
			if (this.isEdit) {
				this.$store.set('certificates/getCertificate!', this.id)
			} else {
				this.certificate = models.Certification('Certificate')
				this.certificate.certification_resources_attributes = []
			}
		},
		getCertificate(id) {
			this.$api.certificates.show(id)
				.then(response => this.certificate = response.data)
				.catch(error => this.$toast.add(error.message, 'error'))
		},
		getResourceName(certification_resource) {
			return _find(this.resources, function (o) {
				return o.id === certification_resource.resource_id
			})?.title
		},
		getResourceType(certification_resource) {
			return _find(this.resources, function (o) {
				return o.id === certification_resource.resource_id
			})?.type
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					console.log(this.certificate.certification_resources_attributes);
					this.$api.certificates.update(this.id, this.certificate)
						.then(response => {
							this.$toast.add('Certificate updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Certificates'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.certificates.create(this.certificate)
						.then(response => {
							this.$toast.add('Certificate created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Certificates'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.certificates.delete(this.id, this.certificate)
				.then(response => {
					this.$toast.add('Certificate deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'Certificates'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
		openAddDialog() {
			this.addDialog = true;
			this.resetNewStep()
		},
		addResource() {
			// console.log(this.certificate.certification_resources_attributes);
			this.newResource.order = this.certificate.certification_resources_attributes.length + 1
			this.certificate.certification_resources_attributes.push(this.newResource)
			this.addDialog = false
		},
		resetNewStep() {
			this.newResource = models.Certification().certification_resources_attributes[0]
		},
		certificationResourceOrderUp(certification_resource, index) {
			if (certification_resource.order === 1) return;
			arrayMoveMutable(this.certificate.certification_resources_attributes, index, index - 1);
			this.reorderCertificationResources();
		},
		certificationResourceOrderDown(certification_resource, index) {
			if (certification_resource.order === this.certificate.certification_resources_attributes.length) return;
			console.log('moving');
			arrayMoveMutable(this.certificate.certification_resources_attributes, index, index + 1);
			this.reorderCertificationResources();
		},
		destroyResource(step, index) {
			this.certificate.certification_resources_attributes[index]['_destroy'] = true;
			arrayMoveMutable(this.certificate.certification_resources_attributes, index, -1);
			this.reorderCertificationResources();
		},
		reorderCertificationResources() {
			this.certificate.certification_resources_attributes.map((step, index) => step.order = index + 1)
		},
	}
}
</script>